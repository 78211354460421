import Station from './station'
import axios from 'axios'

export default class Container {
    constructor() {
        this.google = null
        this.map = null
        this.stations = []
        this.infowindow = []
    }

    waitForGoogle() {
        return new Promise(function (resolve, reject) {
            // Reject the promise after a timeout.
            var timeoutId = setTimeout(function () {
                window['__googleMapsApiOnLoadCallback'] = function () {} // Set the on load callback to a no-op.
                reject(new Error('Could not load the Google Maps API'))
            }, 10000)

            // Hook up the on load callback.
            window['__googleMapsApiOnLoadCallback'] = function () {
                if (timeoutId !== null) {
                    clearTimeout(timeoutId)
                }
                resolve(window.google)
                delete window['__googleMapsApiOnLoadCallback']
            }

            // Prepare the `script` tag to be inserted into the page.
            var scriptElement = document.createElement('script')
            var params = ['key=AIzaSyCiNDutbs1bWsW4edzbASewwbG-XwyZ4qw', 'callback=__googleMapsApiOnLoadCallback']
            scriptElement.src = 'https://maps.googleapis.com/maps/api/js?' + params.join('&')

            // Insert the `script` tag.
            document.body.appendChild(scriptElement)
        })
    }

    start() {
        this.waitForGoogle()
            .then(google => {
                this.googleMapsApiLoaded(google)
            })
    }

    googleMapsApiLoaded(google) {
        this.google = google
        this.map = new this.google.maps.Map(document.getElementById('map'), {
            center: {lat: 51.995194, lng: -115.7499666},
            zoom: 8
        })
        window.map = this.map
        this.google.maps.event.addListener(window.map, 'idle', () => {
            this.ready()
        })
    }

    ready() {
        let date = moment(document.getElementById('passive_date').value, 'MMMM YYYY').format('YYYY-MM-DD')
        if (!date) {
            date = moment().format('YYYY-MM-DD');
        }
        this.loadPassive(date)
        document.getElementById('passive_date').addEventListener('blur', event => {
            this.loadPassive(moment(event.target.value, 'MMMM YYYY').format('YYYY-MM-DD'))
        })
    }

      loadPassive(date) {
        axios.get(window.passiveDataUrl + '?date=' + date).then(response => {
            if (this.stations.length) {
                for (let i = 0; i < this.stations.length; i++) {
                    this.stations[i].destroy()

                    console.log('destroy');
                }
            }
            this.stations = []
            for (let i = 0; i < response.data.length; i++) {
                this.stations.push(new Station(this.google, this.map, response.data[i], this.infowindow))
            }
        })
    }
}
