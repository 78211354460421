export default class Station {
    constructor(google, map, data, infowindow) {
        this.passiveElements = {
            'no2': {
                color: '#afd8f8',
                scale: 20
            },
            'so2': {
                color: '#edc240',
                scale: 50
            }
        }
        this.circles = {
            'so2': null,
            'no2': null
        }
        this.circlesInfo = {
            'so2': null,
            'no2': null
        }
        this.google = google
        this.map = map
        this.infowindow = infowindow
        this.id = data.station_id
        this.name = data.name
        this.address = data.address
        this.so2 = data.so2
        this.no2 = data.no2
        this.date = data.date
        this.longitude = data.longitude
        this.latitude = data.latitude
        this.build()
    }

    clickEvent(event) {
        let target = event.target.id
        target = target.split('_')
        target = target[1]
        if (event.target.checked) {
          this.circles['no2'].setMap(null)
          this.circles['so2'].setMap(null)
          this.circles[target].setMap(this.map)
        } else {
            this.circles[target].setMap(null)
        }
    }

    build() {
        for (var index in this.passiveElements) {
            this.circles[index] = new this.google.maps.Circle({
                center: new this.google.maps.LatLng(parseFloat(this.latitude), parseFloat(this.longitude)),
                fillColor: this.passiveElements[index].color,
                fillOpacity: 0.8,
                radius: (parseFloat(this[index]) * 100) * this.passiveElements[index].scale,
                map: null,
                strokeWeight: 1,
                strokeOpacity: 1,
                strokeColor: this.passiveElements[index].color
            })
            this.circlesInfo[index] = new this.google.maps.InfoWindow({
              content: '<h4>' + this.name + '</h4>' + this[index] + ' ' + index,
            })
            this.infowindow.push(this.circlesInfo[index]);
            var infow = this.infowindow
            var circle = this.circles[index]
            var info = this.circlesInfo[index]
            var id = this.id
            var prevCircle = new this.google.maps.Circle({})
            this.google.maps.event.addListener(circle, 'click', (function (circle, info, map) {
                  return function () {

                    if(prevCircle==circle) {

                      info.close( map, marker );
                      prevCircle=new google.maps.Circle({});
                      return;

                    }

                    var j;
                    for(j = 0; j < infow.length; j++) {
                      infow[j].close();
                    }

                    info.setPosition(circle.getCenter())
                    info.open( map, circle );
                    prevCircle = circle

                  }
            })(circle, info, this.map))
            if (document.getElementById('passive_' + index)) {
                if (document.getElementById('passive_' + index).checked) {
                    this.circles[index].setMap(this.map)
                }
                document.getElementById('passive_' + index).addEventListener('click', event => {
                    this.clickEvent(event)
                })
            }
        }
    }

    destroy() {
        for (var index in this.passiveElements) {
            if (this.circles[index]) {
                this.circles[index].setMap(null)
                delete this.circles[index]
                this.circles[index] = null
            }
            if (document.getElementById('passive_' + index)) {
                document.getElementById('passive_' + index).removeEventListener('click', event => {
                    this.clickEvent(event)
                })
            }
        }
    }
}
